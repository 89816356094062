import { Link } from 'react-router-dom';
import appIcon from '../../assets/images/vpayLogo.svg';
import './app_icon.css';

export default function AppIcon({ height }) {
  return (
    <Link to="/" className="app-icon-link">
      <img src={appIcon} className="app-icon" alt="vpay identity (home page)" style={{ height: `${height}px` }} />
    </Link>
  );
}
