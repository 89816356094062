import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import add from '../../assets/images/add.svg';
import cardOverlay from '../../assets/images/cardOverlay.svg';
import category from '../../assets/images/category-2.svg';
import checkMark from '../../assets/images/check-green.svg';
import arrow from '../../assets/images/downArrow.svg';
import housing from '../../assets/images/house.svg';
import salary from '../../assets/images/money-send.svg';
import phone from '../../assets/images/monitor-mobbile.svg';
import { FlexContainerWithPaddingForCustomInbound, FlexContainerWithPaddingForCustomInboundLower } from '../../pages/merchant/withdraw';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import './popup_widget.css';

export const BorderlessDropdownWithImg = () => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);
  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize this expense</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => setArrowDown(!arrowdown)} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <div className="p-absolute options full-width slide-up d-flex justify-end">
          <div className=" options-content half-width">
            <div className="d-flex j-space-between linebreak">
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 addBg">
                  <img src={add} className="" />
                </div>
                <p>Add a custom category</p>
              </div>
            </div>

            <div className={`${currentSelection === 1 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(1)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={salary} className="salaryColor" />
                </div>
                <p>Salary</p>
              </div>
              {currentSelection === 1 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 2 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(2)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={housing} className="salaryColor" />
                </div>
                <p>Housing and Rent</p>
              </div>
              {currentSelection === 2 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 3 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(3)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 phoneColor">
                  <img src={phone} className="phoneColor" />
                </div>
                <p>Phone & Internet</p>
              </div>
              {currentSelection === 3 && <img src={checkMark} className="fade-in" />}
            </div>
          </div>
        </div>
      )}
      <Spacer height={10} />
    </div>
  );
};

export const ConfirmCustomHeader = ({ from, amount }) => {
  return (
    <div className="p-relative fade-in page-color-background">
      <div className="overlay">
        <img src={cardOverlay} className="p-absolute" style={{ right: '0' }} />
        <Spacer height={40} />
        <div className="d-flex align-center j-space-between text-white p10">
          <div style={{ width: '4%' }}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" className="c-pointer" />
          </div>
          <div className="fw400 full-width d-flex j-center">
            <p>Confirm Details</p>
          </div>
        </div>
      </div>
      <ConfirmCustomDetails from={from} amount={amount} />
      <ConfirmCustomLowerDetails />
    </div>
  );
};

export const ConfirmCustomDetails = ({ from, amount }) => {
  return (
    <div className="custom-confirm-details">
      <div className="d-flex flex-column align-center j-center full-height full-width fw500">
        <div className="text-center ">
          <p className="text-vpay-purple ">From</p>
          <p className="font-size13em">{from}</p>
        </div>
        <Spacer height={10} />
        <div className="text-center">
          <p className="text-vpay-purple">Amount</p>
          <p className=" font-size13em">₦{amount}</p>
        </div>
      </div>
    </div>
  );
};

export const ConfirmCustomLowerDetails = () => {
  return (
    <div>
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Date" rightText="Payment Type" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText="2024/03/06" rightText="Cash" className="pl-40 pr-40" />
      </div>
      <Spacer height={20} />
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Product" rightText="Quantity" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText="Smart Watch" rightText="39" className="pl-40 pr-40" />
      </div>
      <Spacer height={30} />
      <div>
        <AppButton className="full-width d-flex align-center j-center app-button action-color-2 button-nav-padding text-white fade-in" name="Save" />
      </div>
    </div>
  );
};
