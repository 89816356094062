import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { CASHPOINT_ROLES, MERCHANT_ROLES } from '../../constants/roles';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { completeSignup, fetchMerchant, merchantLogin, resetPassword, startSignup } from '../../services/apiSevices';
import { UserActionTypes } from './user_types';

// Temporary Actions

export const openWaitlistModal = (dispatch) => {
  dispatch({
    type: 'OPEN_WAITLIST_MODAL',
  });
};
export const closeWaitlistModal = (dispatch) => {
  dispatch({
    type: 'CLOSE_WAITLIST_MODAL',
  });
};

export const openSessionModal = (dispatch) => {
  dispatch({
    type: 'OPEN_SESSION_MODAL',
  });
};
export const closeSessionModal = (dispatch) => {
  dispatch({
    type: 'CLOSE_SESSION_MODAL',
  });
};

export const setResetMail = (dispatch, email) => {
  dispatch({
    type: 'SET_RESET_MAIL',
    payload: email,
  });
};

// Synchronous Actons

export const fetchUser = (dispatch, user) => {
  dispatch({
    type: 'FETCH_USER_SUCCESS',
    payload: user,
  });
};

export const signInStart = () => ({
  type: UserActionTypes.SIGN_IN_START,
});

export const signInFaliure = (error) => ({
  type: UserActionTypes.SIGN_IN_FALIURE,
  payload: error,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signUpStart = () => ({
  type: UserActionTypes.SIGN_UP_START,
});

export const signUpSuccess = (user) => ({
  type: UserActionTypes.SIGN_UP_SUCCESS,
  payload: user,
});

export const signUpFailure = (error) => ({
  type: UserActionTypes.SIGN_UP_FALIURE,
  payload: error,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFaliure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FALIURE,
  payload: error,
});

export const addDetails = (details) => ({
  type: UserActionTypes.ADD_DETAILS,
  payload: details,
});
export const clearDetails = () => ({
  type: UserActionTypes.CLEAR_DETAILS,
});

export const userPasswordResetStart = () => ({
  type: UserActionTypes.USER_PASSWORD_RESET_START,
});

export const userPasswordResetFailure = (error) => ({
  type: UserActionTypes.USER_PASSWORD_RESET_FAILURE,
  payload: error,
});

export const userPasswordResetSuccess = () => ({
  type: UserActionTypes.USER_PASSWORD_RESET_SUCCESS,
});

export const updateCurrentMerchantStart = () => ({
  type: 'UPDATE_CURRENT_MERCHANT_START',
});

export const updateCurrentMerchantSuccess = (merchant) => ({
  type: 'UPDATE_CURRENT_MERCHANT_SUCCESS',
  payload: merchant,
});

export const openCashpointQR = (dispatch) => {
  dispatch({
    type: 'OPEN_CASHPOINT_QR',
  });
};

export const closeCashpointQR = (dispatch) => {
  dispatch({
    type: 'CLOSE_CASHPOINT_QR',
  });
};

// Asynchronous Actions

export const setCurrentMerchant = (id) => {
  return async (dispatch) => {
    try {
      dispatch(updateCurrentMerchantStart());
      const res = await fetchMerchant(id);
      dispatch(updateCurrentMerchantSuccess(res.data));
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };
};

export const signInStartAsync = (emailAndPassword, history) => {
  return async (dispatch) => {
    try {
      let tourKey = localStorage.getItem('tour');
      localStorage.clear();
      sessionStorage.clear();
      dispatch({
        type: 'CACHED_CLEARD',
      });
      if (tourKey) {
        localStorage.setItem('tour', tourKey);
      }
      dispatch(signInStart());
      const res = await merchantLogin(emailAndPassword);
      const decoded = jwt_decode(res.data.token);
      let payload = {
        ...res.data,
        ...decoded,
      };
      dispatch(signInSuccess(payload));
      if ((decoded.businessid === undefined && MERCHANT_ROLES.includes(payload.role)) || (decoded.cashpointid === undefined && CASHPOINT_ROLES.includes(payload.role))) {
        return dispatch(signInFaliure('Could not complete request'));
      } else {
        localStorage.setItem('v_pay_token', res.data.token);
        axios.defaults.headers.accessToken = res.data.token;
        axios.defaults.headers['b-access-token'] = res.data.token;
        if (payload.role && MERCHANT_ROLES.includes(payload.role)) {
          history.push('/admin/merchant');
        } else if (payload.role && payload.role === 'cashier') {
          history.push('/admin/cashpoint');
        }
      }
    } catch (error) {
      dispatch(signInFaliure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };
};

// if (payload.role && payload.role === 'cashpoint')

export const signUpStartAsync = (userCredentials, history) => {
  let initialSignUpData = {
    email: userCredentials.email,
    password: userCredentials.password,
  };
  return async (dispatch) => {
    try {
      dispatch(signUpStart());
      const res = await startSignup(initialSignUpData);
      const userId = res.data.id;
      const response = await completeSignup({ ...userCredentials, user: userId });
      dispatch(signUpSuccess(response));
      dispatch(clearDetails());
      openNotificationWithIcon('success', 'Success', 'Account Created Successfully');
      history.push('/login');
    } catch (error) {
      dispatch(signUpFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
    }
  };
};

export const userPasswordReset = (mail, history) => {
  return (dispatch) => {
    dispatch(userPasswordResetStart());
    resetPassword({ email: mail })
      .then((response) => {
        const status = response.status;
        if (status === false) {
          return openNotificationWithIcon('warning', 'Warning', 'Could not complete request. Please try again');
        } else {
          openNotificationWithIcon('success', 'Success', response?.message || response?.data?.message);
          dispatch(userPasswordResetSuccess());
          history.push('/verify-account');
        }
      })
      .catch((error) => {
        dispatch(userPasswordResetFailure(error.response?.data?.message));
        return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
      });
  };
};
