import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import success from '../../assets/images/success.svg';
import AppButton from '../../components/app_button/app_button';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import Popup from '../../components/popup/popup';
import { useTransactionId } from '../../contexts/transactionIdContext';
import { useInput } from '../../hooks/use_input';
import { closeTransferPopup, fetchBalance, fetchPayouts, fetchTransactions } from '../../redux/merchant/merchant_actions';
import { authorizePin, fetchMerchant, verifyBankDetails } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import './index.css';
import { InitialPinSetUp } from './profile';
import { getBankName } from './settings';

export const Withdraw = ({ withdrawService, total_referral_balance, balance }) => {
  const dispatch = useDispatch();
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  const banks = useSelector((state) => state.merchant.banks);
  const [position, setPosition] = useState(0);
  const [shouldSetPin, setShouldSetPin] = useState(false);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const amountProps = useInput('', 'text', '', '', true);
  const inboundFee = useSelector((state) => state.merchant.inboundFee);
  const [verifiedAccountName, setVerifiedAccountName] = useState('');
  const data = {
    amount: amountProps.value,
    accountName: verifiedAccountName,
    accountNumber: merchantDetails?.bank?.number,
    bankName: getBankName(merchantDetails?.bank?.code, banks),
    fee: inboundFee,
    description: 'Sent from VPay',
  };

  useEffect(() => {
    const verify = async () => {
      const data = {
        accountnumber: merchantDetails?.bank?.number,
        bankcode: merchantDetails?.bank?.code,
      };
      const res = await verifyBankDetails(data);
      if (res?.data?.accountname === '') {
        return openNotificationWithIcon('error', 'Error', 'Account not found');
      }
      setVerifiedAccountName(res?.data?.accountname);
    };
    verify();
    return () => null;
  }, [merchantDetails?.bank?.number, merchantDetails?.bank?.code]);

  const onClose = () => {
    closeTransferPopup(dispatch);
    setPosition(0);
    dispatch(fetchBalance(id));
    dispatch(fetchPayouts(id));
    dispatch(fetchTransactions(id));
  };
  const goBack = () => {
    setPosition(position - 1);
  };

  const goForward = () => {
    setPosition(position + 1);
  };

  const onConfirmClick = () => {
    if (!merchantDetails.issetpin && !merchantDetails.pin) {
      setShouldSetPin(true);
    } else {
      setShouldSetPin(false);
    }

    goForward();
  };

  const goPosition = () => {
    switch (position) {
      case 0:
        return <WithdrawAmountPopup onClose={onClose} amountProps={amountProps} total_referral_balance={total_referral_balance} balance={balance} setPosition={setPosition} />;
      case 1:
        return <ConfirmWithdraw info={data} flag={true} onConfirmClick={onConfirmClick} goBack={goBack} onClose={onClose} setPosition={setPosition} />;
      case 2:
        return shouldSetPin ? (
          <InitialPinSetUp setShouldSetPin={setShouldSetPin} onClose={onClose} />
        ) : (
          <PinPopup onClose={onClose} apiCall={() => withdrawService(key, { amount: data.amount, type: merchantDetails?.isaggregator ? 'aggregator' : 'bonus' })} goForward={goForward} />
        );
      case 3:
        return <SuccessPopup type="Withdrawal" bottomText="Your withdrawal request has been processed successfully" onClose={onClose} setPosition={setPosition} />;
      default:
        return null;
    }
  };
  return <>{goPosition()}</>;
};

export const WithdrawAmountPopup = ({ amountProps, total_referral_balance, balance, setPosition, onClose }) => {
  const onClick = () => {
    if (amountProps.value === '0') {
      return openNotificationWithIcon('error', 'Error', 'Withdrawable amount cannot be 0');
    } else if (parseFloat(amountProps.value) > parseFloat(total_referral_balance)) {
      return openNotificationWithIcon('error', 'Error', 'Amount is greater than the withdrawable amount');
    } else if (parseFloat(amountProps.value) > parseFloat(balance)) {
      return openNotificationWithIcon('error', 'Error', 'Insufficient balance');
    } else {
      setPosition(1);
    }
  };
  return (
    <Popup width="35%" title="Withdraw Money" onClose={onClose}>
      <Spacer height={30} />
      <FormInput {...amountProps} className="w-input" label="How much do you want to withdraw" placeholder="Enter Amount" name="phone" />
      <button onClick={onClick} className="withdraw-button float-right primary-color">
        Continue
      </button>
    </Popup>
  );
};

export const PinPopup = ({ onClose, goForward, apiCall }) => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const { updateTransactionId } = useTransactionId();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onClick = async () => {
    try {
      if (pin.length === 4) {
        setLoading(true);
        const req = {
          pin,
        };

        await authorizePin(key, req);
        const res = await apiCall();
        if (res.status && res?.data.status) {
          setLoading(false);
          if (isMounted.current) {
            updateTransactionId(res?.data?.data?.wallet_transaction_id);
          }
          goForward(res?.data?.data?.token);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <Popup width="38%" title="Approve Transaction" onClose={onClose}>
      <Spacer height={30} />
      <p className="fw400 font-size-17 text-center text-color no-margin">Enter Your Pin To Confim This Transaction</p>
      <Spacer height={25} />
      <div className="full-width d-flex align-center j-center">
        <PinInputGrid setValue={setPin} />
      </div>
      <Spacer height={40} />
      <div className="full-width d-flex align-center j-center">
        <AppButton isActive={true} isBusy={loading} onClick={onClick} className="c-pointer primary-color pin-con" name="Continue" />
      </div>
    </Popup>
  );
};

export const PinInputGrid = ({ setValue, fields, setFocus }) => {
  const reg = /^\d+$/;
  useEffect(() => {
    const el = document.querySelectorAll('.react-code-input input');
    el.forEach((el) => (el.autocomplete = 'chrome-off'));
  }, []);
  const handleChange = (e) => {
    setValue && setValue(e);
  };
  return <ReactCodeInput type="password" fields={fields ?? 4} pattern={reg} autoComplete="new-password" onChange={handleChange} autoFocus={setFocus !== undefined ? setFocus : true} />;
};

export const OTPInputGrid = ({ setValue, fields, setFocus }) => {
  const reg = /^\d+$/;
  useEffect(() => {
    const el = document.querySelectorAll('.react-code-input input');
    el.forEach((el) => (el.autocomplete = 'chrome-off'));
  }, []);
  const handleChange = (e) => {
    setValue && setValue(e);
  };
  return <ReactCodeInput type="text" fields={fields ?? 6} pattern={reg} autoComplete="new-password" onChange={handleChange} autoFocus={setFocus !== undefined ? setFocus : true} />;
};

export const BulkPinInputGrid = ({ setValue, fields }) => {
  const reg = /^\d+$/;
  useEffect(() => {
    const el = document.querySelectorAll('.react-code-input input');
    el.forEach((el) => (el.autocomplete = 'chrome-off'));
    el.forEach((el) => (el.autocomplete = 'cc-number'));
    // remove any pin that pre populates
    el.forEach((el) => (el.value = ''));
    el.forEach((el) => (el.name = 'text-char'));
  }, []);
  const handleChange = (e) => {
    setValue && setValue(e);
  };
  return <ReactCodeInput type="password" fields={fields ?? 4} pattern={reg} autoComplete="cc-number" onChange={handleChange} autoFocus={true} />;
};

export const ConfirmWithdraw = ({ onClose, goBack, info, onConfirmClick, flag, bankCode }) => {
  // get the previous account number if the account is still under review
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const [verifiedAccountName, setVerifiedAccountName] = useState('');
  const { data, isLoading } = useQuery({ queryKey: ['fetchMerchant'], queryFn: () => fetchMerchant(id), refetchOnWindowFocus: true });

  const accountUnderReview = data?.data?.bank?.underreview;
  const previousAccount = data?.data?.previousbank;

  const verify = async () => {
    const info = {
      accountnumber: previousAccount?.number,
      bankcode: previousAccount?.code,
    };
    const res = await verifyBankDetails(info);
    if (res?.data?.accountname === '') {
      return openNotificationWithIcon('error', 'Error', 'Account not found');
    }
    setVerifiedAccountName(res?.data?.accountname);
  };
  if (!isLoading && data.data._id) {
    verify();
  }

  return (
    <Popup width="35%" title="Confirm" onClose={onClose}>
      <Spacer height={50} />
      <FlexContainerWithPadding leftText="Amount" rightText={`₦ ${formatNumber(info?.amount)}`} />
      <Spacer height={25} />
      {!accountUnderReview && <FlexContainerWithPadding leftText="Beneficiary" rightText={info?.accountName} />}
      {accountUnderReview && <FlexContainerWithPadding leftText="Beneficiary" rightText={verifiedAccountName === '' ? <LoadingOutlined /> : verifiedAccountName} />}
      <Spacer height={25} />
      <div className="ben-gradient-wrapper full-width plr-20 primary-color">
        {accountUnderReview ? <FlexContainerWithoutPadding leftText="From" rightText={previousAccount?.number} /> : <FlexContainerWithoutPadding leftText="From" rightText={info?.accountNumber} />}
        <Spacer height={10} />
        {accountUnderReview ? (
          <FlexContainerWithoutPadding leftText="Beneficiary Bank" rightText={previousAccount?.name} />
        ) : (
          <FlexContainerWithoutPadding leftText="Beneficiary Bank" rightText={info?.bankName} />
        )}
        <Spacer height={10} />
        {!flag && <FlexContainerWithoutPadding leftText="Transaction Fee" rightText={`₦ ${bankCode && bankCode === '999999' ? 0 : info?.fee}`} />}
      </div>
      <Spacer height={25} />
      <FlexContainerWithPadding leftText="Remark" rightText={info?.description} className="remark-wrapper" />
      <Spacer height={50} />
      <div className="full-width d-flex align-center j-space-between plr-20">
        <button onClick={goBack} className="text-color c-btn font-size-18 c-pointer">
          Back
        </button>
        <button onClick={onConfirmClick} className="withdraw-button primary-color">
          Continue
        </button>
      </div>
    </Popup>
  );
};

export const ConfirmAirtimeOrData = ({ onClose, goBack, onConfirmClick, data }) => {
  return (
    <Popup width="35%" title="Confirm" onClose={onClose}>
      <Spacer height={50} />
      <FlexContainerWithPadding leftText="Amount" rightText={`₦ ${formatNumber(data.amount)}`} />
      <Spacer height={25} />
      <FlexContainerWithPadding leftText="Phone Number" rightText={data.phone} />
      <Spacer height={25} />
      <div className="ben-gradient-wrapper full-width plr-20 primary-color">
        <FlexContainerWithoutPadding leftText="Network" rightText={data.network} />
        <Spacer height={10} />
        <FlexContainerWithoutPadding leftText="Plan" rightText={data.plan} />
      </div>
      <Spacer height={50} />
      <div className="full-width d-flex align-center j-space-between plr-20">
        <button onClick={goBack} className="text-color c-btn font-size-18 c-pointer">
          Back
        </button>
        <button onClick={onConfirmClick} className="withdraw-button primary-color">
          Continue
        </button>
      </div>
    </Popup>
  );
};

export const ConfirmCableOrUtilty = ({ onClose, goBack, onConfirmClick, data }) => {
  return (
    <Popup width="35%" title="Confirm" onClose={onClose}>
      <Spacer height={50} />
      <FlexContainerWithPadding leftText="Amount" rightText={`₦ ${formatNumber(data.amount)}`} />
      <Spacer height={25} />
      <FlexContainerWithPadding leftText="Phone Number" rightText={data.phone} />
      <Spacer height={25} />
      <div className="ben-gradient-wrapper full-width plr-20 primary-color">
        <FlexContainerWithoutPadding leftText="Provider" rightText={data.provider} />
        <Spacer height={10} />
        <FlexContainerWithoutPadding leftText="Plan" rightText={data.plan} />
        {data.smartcard_number && (
          <>
            <Spacer height={10} />
            <FlexContainerWithoutPadding leftText="Smart Card" rightText={data.smartcard_number} />
          </>
        )}
        {data.meter_number && (
          <>
            <Spacer height={10} />
            <FlexContainerWithoutPadding leftText="Smart Card" rightText={data.meter_number} />
          </>
        )}
      </div>
      <Spacer height={50} />
      <div className="full-width d-flex align-center j-space-between plr-20">
        <button onClick={goBack} className="text-color c-btn font-size-18 c-pointer">
          Back
        </button>
        <button onClick={onConfirmClick} className="withdraw-button primary-color">
          Continue
        </button>
      </div>
    </Popup>
  );
};

export const SuccessPopup = ({ type, bottomText, onClose, isBusy, onDownloadReceiptClick }) => {
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const dispatch = useDispatch();

  const onClick = () => {
    onClose();
    dispatch(fetchBalance(merchantID));
    dispatch(fetchTransactions(merchantID));
    dispatch(fetchPayouts(merchantID));
  };

  return (
    <Popup width="35%" onClose={onClose}>
      <Spacer height={50} />
      <div className="d-flex align-center j-center">
        <img src={success} alt="" />
      </div>
      <Spacer height={30} />
      <p className="fw500 font-size-25 text-center text-color">{`${type} Successful`}</p>
      <Spacer height={15} />
      <div className="d-flex align-center j-center">
        <p className="text-center text-color fw400 font-size-18" style={{ maxWidth: '300px' }}>
          {bottomText}
        </p>
      </div>
      <Spacer height={30} />
      <div className="d-flex align-center j-center">
        <AppButton isActive={true} onClick={onClick} name="Back To Dashboard" className="text-color ba-b full-width fw400 font-size-18" />
      </div>
      <Spacer height={20} />
      {type === 'Transfer' && (
        <AppButton isActive={true} isBusy={isBusy} onClick={onDownloadReceiptClick} className="font-size-16 b-tn-padding action-color-bg fw400 full-width primary-color" name="Download Receipt" />
      )}
    </Popup>
  );
};

export const FlexContainerWithPadding = ({ leftText, rightText, className }) => (
  <div className={`full-width d-flex align-center j-space-between plr-20 ${className}`}>
    <p className="no-margin fw400 mobile-font-size-12 font-size-17">{leftText}</p>
    <p className="no-margin fw500 mobile-font-size-12 font-size-17 text-right">{rightText}</p>
  </div>
);

export const FlexContainerWithoutPadding = ({ leftText, rightText }) => (
  <div className="full-width d-flex align-center j-space-between ">
    <p className="no-margin half-width fw400 mobile-font-size-12  font-size-17">{leftText}</p>
    <p className="no-margin  fw500 text-right mobile-font-size-12 font-size-17">{rightText}</p>
  </div>
);

export const FlexContainerWithPaddingForCustomInbound = ({ leftText, rightText, className }) => (
  <div className={`full-width d-flex align-center j-space-between ${className}`}>
    <p className="no-margin fw400 mobile-font-size-12 font-size-14 faint-text">{leftText}</p>
    <p className="no-margin fw400 mobile-font-size-12 font-size-14 faint-text">{rightText}</p>
  </div>
);

export const FlexContainerWithPaddingForCustomInboundLower = ({ leftText, rightText, className }) => (
  <div className={`full-width d-flex align-center j-space-between ${className}`}>
    <p className="no-margin fw700 mobile-font-size-12 font-size-14 header-color">{leftText}</p>
    <p className="no-margin fw700 mobile-font-size-12 font-size-14 header-color">{rightText}</p>
  </div>
);
