import { Select } from 'antd';
import 'antd/dist/antd.css';

import './form_dropdown.css';

export function FormDropdown({ options, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width }) {
  return (
    <div style={{ width }} className="dropdown-container p-relative">
      {label && <span className="dropdown-label fw200 ">{label}</span>}
      <div className="p-relative">
        <Select
          showSearch
          className={`${className} ${error && error[name] ? 'has-error-1' : ''}`}
          bordered={false}
          size="large"
          loading={loading}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={onChange}
          name={name}
          suffixIcon={suffixIcon}
          defaultValue={defaultValue || null}
        >
          {options?.map((option, idx) => (
            <Select.Option value={option} key={idx}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export function BorderLessFormDropdown({ options, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width }) {
  return (
    <div style={{ width }} className="dropdown-container p-relative">
      {label && <span className="dropdown-label fw200 ">{label}</span>}
      <div className="p-relative">
        <Select
          showSearch
          className={`${className} unique-borderless ${error && error[name] ? 'has-error-1' : ''}`}
          bordered={false}
          size="large"
          loading={loading}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={onChange}
          name={name}
          suffixIcon={suffixIcon}
          defaultValue={defaultValue || null}
        >
          {options?.map((option, idx) => (
            <Select.Option value={option} key={idx}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}
