import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormInput } from '../../../components/form_input/form_input';
import { FormLayout, ParentColouredBackground } from '../../../components/layout/layout';
import { useInput } from '../../../hooks/use_input';
import { signInStartAsync } from '../../../redux/user/user_actions';
import { selectLoading } from '../../../redux/user/user_selector';

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  // memoized selector
  const loading = useSelector(selectLoading);

  const emailProps = useInput('', 'text');
  const passwordProps = useInput('', 'password');

  useEffect(() => {
    dispatch({
      type: 'LOADING_FALSE',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    const data = {
      email: emailProps.value,
      password: passwordProps.value,
      app: 'merchant',
      life_span: checked ? 'long' : 'short',
    };
    if (data.email && data.password) {
      dispatch(signInStartAsync(data, history));
    }
  };

  const checkEnter = (e) => {
    if (e.key === 'Enter' || e.code === 'Enter') onClick(e);
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <ParentColouredBackground bottomText="Don’t have an account?" linkText="Signup" lastText="Forgot your password?" route="/signup" onClick={() => history.push('/forgot-password')}>
      <FormLayout headerText="LOGIN AS A PARENT" buttonText="Login to my account" onClick={onClick} isBusy={loading}>
        <input type="hidden" value="something" />
        <FormInput className="important-full-width" label="Email Address / Phone Number" {...emailProps} onKey={checkEnter} />
        <FormInput className="important-full-width" label="Password" isPassword {...passwordProps} onKey={checkEnter} />
        <div className="fw400 d-flex full-width mb-30 align-start justify-end">
          <Checkbox checked={checked} onChange={onChange}>
            Keep me logged in
          </Checkbox>
        </div>
      </FormLayout>
    </ParentColouredBackground>
  );
}
