import { PlusOutlined } from '@ant-design/icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '../layout/layout';
import './app_button.css';

export default function AppButton({ name, className, onClick, isActive, type, isBusy, color, backgroundColor, disabled }) {
  return (
    <button style={{ color, backgroundColor }} className={`app-button ${className || ''}`} onClick={isActive && !isBusy ? onClick : null} type={type || 'button'} disabled={disabled}>
      {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
    </button>
  );
}

export function AppButtonWithIcon({ name, className, onClick, isActive, type, isBusy, color, backgroundColor, disabled, icon, space }) {
  return (
    <button style={{ color, backgroundColor }} className={`app-button ${className || ''}`} onClick={isActive && !isBusy ? onClick : null} type={type || 'button'} disabled={disabled}>
      {icon ? <img src={icon} /> : <PlusOutlined />} <Spacer width={space} /> {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
    </button>
  );
}
export function AppButtonIcon({ name, className, onClick, isActive, type, isBusy, color, backgroundColor, disabled, icon, margin }) {
  return (
    <button style={{ color, backgroundColor }} className={`app-button ${className || ''}`} onClick={isActive && !isBusy ? onClick : null} type={type || 'button'} disabled={disabled}>
      {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name} <Spacer width={margin} /> {icon}
    </button>
  );
}
