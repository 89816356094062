import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './popup_header.css';

export const PopupHeader = ({ goBack, text }) => {
  return (
    <div className="d-flex align-center">
      <div className="c-pointer width-2" onClick={() => goBack(false)}>
        <FontAwesomeIcon icon={faAngleLeft} size="2x" />
      </div>
      <p className="text-center full-width fw500 font-size13em">{text}</p>
    </div>
  );
};
